import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const ExclamationPointCircleIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth={1.5} className={styles.icon({
  ...props,
  borderRadius,
  color
})} viewBox="0 0 24 24" width={props.width} height={props.height} role="img" aria-hidden="true" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0m-9 3.75h.008v.008H12z" /></svg>);
ExclamationPointCircleIcon.displayName = "ExclamationPointCircleIcon";
export default ExclamationPointCircleIcon;